* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  
  
  .btns{
    position: relative;
    background: #444;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
  }
  
  .btns:hover {
    letter-spacing: 0.2rem;
    padding: 1.1rem 3.1rem;
    background: var(--clr);
    color: var(--clr);
    /* box-shadow: 0 0 35px var(--clr); */
    animation: box 3s infinite;
  }
  
  .btns::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #272822;
  }
  
  .btns span {
    position: relative;
    z-index: 1;
  }
  button i {
    position: absolute;
    inset: 0;
    display: block;
  }
  
  button i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  button:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  button i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  button:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes box {
    0% {
      box-shadow: #27272c;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #27272c;
    }
  }
  
  /* background.css */
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .image-slide-in {
    animation: slideIn 1.5s ease-out forwards;
  }
  
  
  .responsive-video {
    width: 100%;
    max-width: 600px; /* Set a max width to maintain aspect ratio */
    border-radius: 10px;
    box-shadow: var(--shadow-light); /* You can adjust for dark/light themes */
  }